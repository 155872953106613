import { Formik, useFormikContext } from "formik";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { array, object } from "yup";

import { updateInterestGroups } from "../../../../actions/settingActions";
import { openSnackbar } from "../../../../actions/uiActions";
import { GET } from "../../../../util/apiHelpers";
import FieldGroupHeader from "../../../General/FormCard/FieldGroupHeader";
import FormCard from "../../../General/FormCard/FormCard";
import FormFooter from "../../../General/FormCard/FormFooter";
import { ConnectedSelect } from "../../../General/FormInputs/ConnectedInputs";

const sortOptions = (options) =>
  sortBy(
    map(options, (option) => ({
      value: option.id,
      label: option.attributes.name,
    })),
    (option) => option.label
  );

const validationSchema = object().shape({
  interestGroups: array(),
});

const GroupMembership = () => {
  const [groupOptions, setGroupOptions] = useState([]);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.attributes);

  const initialValues = useMemo(() => ({
    interestGroups: settings.interestGroups.map(({ id }) => id),
  }), [settings]);

  useEffect(() => {
    GET("/public/api/v1/groups").then((res) => {
      const options = sortOptions(res.data.data);
      setGroupOptions(options);
    });
  }, []);

  const handleSubmit = (values, context) => {
    return dispatch(updateInterestGroups(values))
      .then(() => {
        context.setSubmitting(false);
        context.resetForm();
        dispatch(openSnackbar("Chapter successfully updated"));
      })
      .catch(() => {
        context.setSubmitting(false);
        dispatch(openSnackbar("An error occurred during submission"));
      });
  }

  return (
    <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={handleSubmit}
  >
      {() => (
        <FormCard>
          <FieldGroupHeader
            title="Special Interest Groups"
            iconClass="flaticon2-group"
          />
          <ConnectedSelect
            id="interestGroups"
            label="Groups"
            options={groupOptions}
            isMulti
          />
          <FormFooter />
        </FormCard>
      )}
    </Formik>
  )
}

export default GroupMembership;
