import React from "react";

import withGlobalProviders from "../General/withGlobalProviders";

import GroupMembership from "./edit/GroupMembership/GroupMembership";
import UpdateChapter from "./edit/UpdateChapter/UpdateChapter";
import UpdatePersonalDetailsForm from "./edit/UpdatePersonalDetailsForm/UpdatePersonalDetailsForm";
import UpdateProfessionalDetailsForm from "./edit/UpdateProfessionalDetailsForm/UpdateProfessionalDetailsForm";

function Edit() {
  return (
    <div>
      <div className="row row-full-height">
        <UpdatePersonalDetailsForm />
        <UpdateProfessionalDetailsForm />
      </div>
      <div className="row row-full-height">
        <UpdateChapter />
        <GroupMembership />
      </div>
    </div>
  )
};

export default withGlobalProviders(Edit);
