import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import { Formik } from "formik";

import map from "lodash/map";
import sortBy from "lodash/sortBy";
import queryString from "query-string";
import React, { useEffect, useState } from "react";


import { GET } from "../../util/apiHelpers";

import ConnectedCheckbox from "../General/FormInputs/connected/ConnectedCheckbox";
import {
  ConnectedDropdown,
  ConnectedTextField,
} from "../General/FormInputs/ConnectedInputs";

import LinkedinButton from "./LinkedInButton";
import { buildValidationSchema, initialValues } from "./util";

const sortOptions = (options) =>
  sortBy(
    map(options, (option) => ({
      value: option.id,
      label: `${option.attributes.state} - ${option.attributes.name}`,
      selected: false,
    })),
    (option) => option.label
  );

const interestedOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const Form = ({ event, nationalId }) => {
  const handleSubmit = (data) => {
    const formData = data;
    formData.eventId = event && event.id;
    formData.ta = true;
    formData.registration_attempt = true;
    formData.entity_type = "User";

    const queryParams = queryString.stringify(formData);

    window.location.href = "/users/auth/linkedin?" + queryParams;
  };

  const [groupOptions, setGroupOptions] = useState([]);

  useEffect(() => {
    GET("/public/api/v1/chapters").then((res) => {
      const options = sortOptions(res.data.data);
      options.push({ value: nationalId, label: "Other", selected: false });
      setGroupOptions(options);
    });
  }, []);

  const renderOnlineTerms = () => (
    <div>
      I agree to the{" "}
      <a
        href="https://www.talktalent.com/terms"
        style={{ textDecoration: "underline" }}
      >
        online terms
      </a>
    </div>
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={buildValidationSchema(nationalId)}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, values }) => (
        <div
          style={{
            maxWidth: 500,
            margin: "auto",
            paddingLeft: 24,
            paddingRight: 24,
          }}
        >
          <ConnectedDropdown
            id="chapterId"
            options={groupOptions}
            label="Chapter"
          />
          {values.chapterId === nationalId && (
            <ConnectedTextField
              id="otherChapterInput"
              label={
                <React.Fragment>
                  Other
                  <Tooltip
                    title={
                      <span style={{ fontSize: 12 }}>
                        Please enter the city, and either the state or country
                      </span>
                    }
                    placement="top"
                  >
                    <InfoOutlined
                      style={{
                        fontSize: 16,
                        marginBottom: 2,
                        marginLeft: 4,
                      }}
                    />
                  </Tooltip>
                </React.Fragment>
              }
            />
          )}
          <ConnectedTextField id="firstNameInput" label="First Name" />
          <ConnectedTextField id="lastNameInput" label="Last Name" />
          <ConnectedTextField id="workEmailInput" label="Work Email" />
          <ConnectedTextField id="personalEmailInput" label="Personal Email" />
          <ConnectedTextField id="companyNameInput" label="Company Name" />
          <ConnectedTextField id="jobTitleInput" label="Job Title" />
          <ConnectedTextField
            id="linkedInUrlInput"
            label="Linkedin Profile URL"
          />
          <ConnectedDropdown
            id="leadershipInterest"
            options={interestedOptions}
            label={
              "Are you interested in learning about TALK leadership positions?"
            }
            selectProps={{ isSearchable: false }}
          />
          <ConnectedCheckbox id="agreesToTerms" label={renderOnlineTerms()} />
          <LinkedinButton onClick={handleSubmit} />
        </div>
      )}
    </Formik>
  );
};

export default Form;
