import classnames from "classnames";
import { connect } from "formik";
import React from "react";

const FormFooter = ({
  formik: { handleSubmit, submitCount, errors, isSubmitting, dirty },
}) => {
  const showErrorMessage = Boolean(submitCount && Object.keys(errors).length);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        marginTop: 10,
      }}
    >
      <div className="kt-form__actions">
        <button
          type="submit"
          onClick={handleSubmit}
          className={classnames(
            "btn",
            "btn-brand",
            "btn-md",
            "btn-tall",
            "btn-wide",
            "kt-font-bold",
            "kt-font-transform-u",
            { disabled: !dirty || isSubmitting }
          )}
          disabled={!dirty || isSubmitting}
        >
          Update
        </button>
      </div>
      {showErrorMessage && (
        <div style={{ color: "#f45b5e", marginLeft: 10 }}>
          Please review the fields highlighted in red.
        </div>
      )}
    </div>
  );
};

export default connect(FormFooter);
