import Tooltip from "@material-ui/core/Tooltip";
import InfoOutlined from "@material-ui/icons/InfoOutlined";
import classnames from "classnames";
import { Formik } from "formik";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";

import { changeChapter } from "../../../../actions/settingActions";
import { openSnackbar } from "../../../../actions/uiActions";
import { GET } from "../../../../util/apiHelpers";
import FieldGroupHeader from "../../../General/FormCard/FieldGroupHeader";
import FormCard from "../../../General/FormCard/FormCard";
import { ConnectedDropdown, ConnectedTextField } from "../../../General/FormInputs/ConnectedInputs";

const sortOptions = (options) =>
  sortBy(
    map(options, (option) => ({
      value: option.id,
      label: `${option.attributes.state} - ${option.attributes.name}`,
    })),
    (option) => option.label
  );

const buildValidationSchema = (nationalId) => {
  return object().shape({
    chapterId: string().required("Cannot be blank"),
    otherChapterInput: string()
      .nullable()
      .when("chapterId", {
        is: nationalId,
        then: string().required("Cannot be blank"),
      }),
  });
}

const UpdateChapter = () => {
  const [groupOptions, setGroupOptions] = useState([]);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);

  const initialValues = useMemo(() => ({
    chapterId: settings.attributes.chapter.id,
    otherChapterInput: settings.attributes.chapter.preferredLocation,
    primary: true,
  }), [settings.attributes]);

  const nationalId = settings.attributes.nationalGroupId;
  useEffect(() => {
    GET("/public/api/v1/chapters").then((res) => {
      const options = sortOptions(res.data.data);
      options.push({ value: nationalId, label: "Other" });
      setGroupOptions(options);
    });
  }, [nationalId]);

  const handleSubmit = (values, context) => {
    return dispatch(changeChapter(values))
      .then(() => {
        context.setSubmitting(false);
        context.resetForm();
        dispatch(openSnackbar("Chapter successfully updated"));
      })
      .catch(() => {
        context.setSubmitting(false);
        dispatch(openSnackbar("An error occurred during submission"));
      });
  }

  const recent = moment().diff(moment(settings.attributes.chapter.updatedAt), 'hours') < 24;
  
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={buildValidationSchema(nationalId)}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, dirty, values }) => (
        <FormCard>
          <FieldGroupHeader
            title="Chapter Membership"
            iconClass="flaticon-suitcase"
          />
          <ConnectedDropdown id="chapterId" label="Chapter" selectProps={{ isDisabled: recent }} 
            options={groupOptions}
          />
          {values.chapterId === nationalId && (
            <ConnectedTextField
              id="otherChapterInput"
              inputProps={{ disabled: recent }}
              label={
                <>
                  Other
                  <Tooltip
                    title={
                      <span style={{ fontSize: 12 }}>
                        Please enter the city, and either the state or country
                      </span>
                    }
                    placement="top"
                  >
                    <InfoOutlined
                      style={{
                        fontSize: 16,
                        marginBottom: 2,
                        marginLeft: 4,
                      }}
                    />
                  </Tooltip>
                </>
              }
            />
          )}
          <div style={{ paddingBottom: 8, fontSize: 16 }}>When you change chapters you will lose your current board membership.</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div className="kt-form__actions">
              <button
                onClick={() => handleSubmit()}
                className={classnames(
                  "btn",
                  "btn-brand",
                  "btn-md",
                  "btn-tall",
                  "btn-wide",
                  "kt-font-bold",
                  "kt-font-transform-u",
                  { disabled: isSubmitting }
                )}
                disabled={recent || !dirty || isSubmitting}
              >
                Change Chapter
              </button>
            </div>
            {recent && (
              <div style={{ marginLeft: 10, fontSize: 14 }}>
                You can only change chapter once a day
              </div>
            )}
          </div>
        </FormCard>
      )}
    </Formik>
  )
}

export default UpdateChapter;
